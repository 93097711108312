import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/brands';

import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

jQuery(document).ready(function($) {
	var windowWidth = $(window).width()

/* ====================== MOBILE NAV ======================  */
    $('.menu-toggle').on("click", function() {
        $(this).toggleClass('active');
        $('header nav').toggleClass('open');
        $('header').toggleClass('open');
        $('body').toggleClass('noscroll');
    })

    if(windowWidth < 1280) {
	    $('#menu-main-menu .menu-item-has-children').on("click", function() {
	       $(this).toggleClass('open');
	       $(this).find('.sub-menu').slideToggle();
	    })

	    $('.sub-menu a').on("click", function(event) {
	        event.stopPropagation();
	    });
	}

/* ====================== Semi Sticky Nav ======================  */
	var prevScrollpos = window.pageYOffset;
	var currentScrollPos = window.pageYOffset;

	if(currentScrollPos > 120) {
		$("header").addClass("headerscrolled");
	}

	window.onscroll = function() {
		var currentScrollPos = window.pageYOffset;

		if ((prevScrollpos > currentScrollPos)) {
			$('header').addClass('show');
		} else if((prevScrollpos < currentScrollPos)){
			$('header').removeClass('show');
		}
		else {
			$('header').removeClass('show');
		}

		if(currentScrollPos > 120) {
			$("header").addClass("headerscrolled");
		} else {
			$("header").removeClass("headerscrolled show");
		}

		prevScrollpos = currentScrollPos;
	}

/* ====================== Init Fancybox ======================  */
	Fancybox.bind("[data-fancybox]", {
	});

/* ====================== Latest Posts Carousel ======================  */
	const latest_posts_carousel = new Swiper('.latest-posts-carousel .swiper', {
       	slidesPerView: 1.25,
       	speed: 600,
       	loop: false,
       	watchSlidesProgress: true,
       	spaceBetween: 8,
       	navigation: {
			nextEl: ".latest-posts-carousel .swiper-button-next",
			prevEl: ".latest-posts-carousel .swiper-button-prev",
		},
		breakpoints: {
			768: {
				slidesPerView: 2.2,
				spaceBetween: 30,
			},
			1024: {
				slidesPerView: 3.2,
				spaceBetween: 30,
			},

			1280: {
				slidesPerView: 3,
				spaceBetween: 30,
			},
		},
	});

/* ====================== Philosophy Diagram ======================  */
	$('.triangle-point:first-of-type, .point-content:first-of-type').addClass('active');
	$('.triangle-point').on("click", function () {
		var relatedContent = $(this).attr('data-related');

		$('.point-content, .triangle-point').removeClass('active');
		$(this).addClass('active');
        $('.point-content[data-related=' + relatedContent + ']').addClass('active');
    });

	function autoPlayDiagram() {
		if(!$('.triangle-point.active').next('.triangle-point').length) {
			$('.triangle-point, .point-content').removeClass('active');
			$('.triangle-point:first-of-type, .point-content:first-of-type').addClass('active');
		} else {
			$('.point-content').removeClass('active');
			$('.triangle-point').removeClass('current');
			$('.triangle-point.active').addClass('current');
			$('.triangle-point.active').removeClass('active');
			$('.triangle-point.current').next('.triangle-point').addClass('active');
			var relatedContent = $('.triangle-point.active').attr('data-related');
			$('.point-content[data-related=' + relatedContent + ']').addClass('active');
		}
	}

   setInterval(autoPlayDiagram, 8000);

/* ====================== Design Customisatin ======================  */
	$('.design-customisation .btn').on("click", function () {
		$('.design-customisation .btn').removeClass('active');
		$(this).addClass('active');
		var relatedImage = $(this).attr('image-related');

		$('.design-customisation .image-container').attr('class', 'image-container ' + relatedImage );
    });

/* ====================== Scroll Indicator ======================  */
	if ($('.scroll-indicator').length > 0) {
		window.onload = function() {
		  const container = document.querySelector('body');
		  const svg = document.querySelector('svg');
		  const progressBar = document.querySelector('.progress-bar');
		  const totalLength = progressBar.getTotalLength();

		  progressBar.style.strokeDasharray = totalLength;
		  progressBar.style.strokeDashoffset = totalLength;

		  setProgress(container, progressBar, totalLength);

		  window.addEventListener('scroll', () => {
		    setProgress(container, progressBar, totalLength);
		  });
		}

		function setProgress(container, progressBar, totalLength) {
		  const clientHeight = document.documentElement.clientHeight;
		  const scrollHeight = document.documentElement.scrollHeight;
		  const scrollTop = document.documentElement.scrollTop;

		  const percentage = scrollTop / (scrollHeight - clientHeight);
		  if(percentage === 1) {
		    container.classList.add('completed');
		  } else {
		    container.classList.remove('completed');
		  }
		  progressBar.style.strokeDashoffset = totalLength - totalLength * percentage;
		}
	}

/* ====================== Submit Gravity Form ======================  */
	$(document).on('gform_post_render', function(){
	     $('#gform_fields_3 .btn, #gform_fields_4 .btn').on('click', function() {
        	$(this).parents('.gform_wrapper').find('input[type=submit]').click();
        });
	});

/* ====================== Accordion ======================  */
	//$('.accordion .accordion-row:first-of-type .accordion-row-intro').addClass('active');
	//$('.accordion .accordion-row:first-of-type .accordion-row-content').slideDown();
	//$('.accordion .accordion-row:first-of-type .accordion-row-content').addClass('active');

	$('.accordion .accordion-row-intro').on('click', function () {
		if($(this).hasClass('active')) {
			$(this).removeClass('active');
			$(this).next('.accordion-row-content').slideUp();
			$(this).next('.accordion-row-content').removeClass('active');
		} else {
			$('.accordion .accordion-row-intro').removeClass('active');
			$('.accordion-row-content').slideUp();
			$('.accordion-row-content').removeClass('active');

			$(this).addClass('active');
			$(this).next('.accordion-row-content').slideDown();
			$(this).next('.accordion-row-content').addClass('active');
		}
    });

/* ====================== GSAP ======================  */
	let animate = gsap.utils.toArray('.animate')
	animate.forEach((item, index) => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				once: true,
				start:"0 80%",
				toggleActions:"restart none none reset",
				end: "500",
				markers: false,
				toggleClass: 'animateelement'
			}
		});
	});

    document.querySelectorAll('h1:not(.no-anim):not(.homepage-title), h2:not(.no-anim), h3:not(.no-anim), h4:not(.no-anim)').forEach(function(heading) {
    	// stops headings briefly showing
    	heading.style.opacity = 1;

		gsap.to(heading.querySelectorAll(".word"), {
			scrollTrigger: {
				trigger: heading,
				start: 'top 85%',
				markers: false,
			},
			y: 0,
			ease: "power4.out",
			opacity: 1,
			duration: .4,
		});
		});

/* ====================== Smooth Scroll - Doesn't conflict with ajax smooth scroll ======================  */
	// Add smooth scrolling to all links
	$("a").on('click', function(event) {

	// Make sure this.hash has a value before overriding default behavior
	if (this.hash !== "") {
	  // Prevent default anchor click behavior
	  event.preventDefault();

	  // Store hash
	  var hash = this.hash;

	  // Using jQuery's animate() method to add smooth page scroll
	  // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
	  $('html, body').animate({
	    scrollTop: $(hash).offset().top
	  }, 500, function(){

	    // Add hash (#) to URL when done scrolling (default click behavior)
	    window.location.hash = hash;
	  });
	} // End if
	});



/* ====================== Headr Search ======================  */
$('header .search .button').on('click', function(){
	$('header').toggleClass('search-open');

})

});
